'use strict'

import store from '../store'
import axios from 'axios'
import { kmSettings } from '../../public/settings'

const setRequest = (record) => {
  // Axios base settings
  let request = {}
  let token = store.state.login.account.token
  request.user = store.state.login.account.user
  if (token) {
    request.headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  } else {
    request.headers = {
      'Content-Type': 'application/json'
    }
  }
  request.params = {
    appid: store.state.config.XbAppID,
    type: 'data',
    request: 'km_settings',
    record: record
  }
  return request
}
const state = {
  XbUrl: kmSettings.XbUrl,
  XbPath: kmSettings.XbPath,
  XbUser: kmSettings.XbUser,
  XbProject: kmSettings.XbProject,
  XbDirectory: kmSettings.XbDirectory,
  XbProfiles: kmSettings.XbProfiles,
  XbAppID: kmSettings.XbAppID,
  XbRoot: false,
  XbAdmins: false
}

const getters = {
  getRoot: (state) => {
    return state.XbRoot
  },
  getAdmins: (state) => {
    return state.XbAdmins
  }
}

const mutations = {
  setRoot: (state, root) => {
    state.XbRoot = root
  },
  setAdmins: (state, admins) => {
    state.XbAdmins = admins
  }
}

const actions = {
  prepRoot: ({ commit }) => {
    let request = setRequest('roots')
    axios({
      headers: request.headers,
      url: '',
      method: 'get',
      params: request.params
    }).then(getResponse => {
      if (getResponse.data) {
        let data = JSON.parse(getResponse.data.data)[0]
        if (Array.isArray(data.roots) && data.roots.includes(request.user)) {
          commit('setRoot', true)
        } else {
          commit('setRoot', false)
        }
      } else {
        commit('setRoot', false)
      }
    }).catch(() => {
      commit('setRoot', false)
    })
  },
  prepAdmins: ({ commit }) => {
    let request = setRequest('admins')
    axios({
      headers: request.headers,
      url: '',
      method: 'get',
      params: request.params
    }).then(getResponse => {
      if (getResponse.data) {
        let data = JSON.parse(getResponse.data.data)[0]
        if (Array.isArray(data.admins) && data.admins.includes(request.user)) {
          commit('setAdmins', true)
        } else {
          commit('setAdmins', false)
        }
      } else {
        commit('setAdmins', false)
      }
    }).catch(() => {
      commit('setAdmins', false)
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
