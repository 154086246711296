<template>
  <nav class="navbar has-shadow is-white">
    <div class="container">
        <div class="navbar-brand">
          <router-link class="navbar-item" :to="{ path: '/' }">
            <img src="../assets/logo.png" :alt="title">
          </router-link>
          <div id="burger" class="navbar-burger" @click="openMenu">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div id="menu" class="navbar-menu">
          <div class="navbar-start">
            <router-link v-if="getLogged" class="navbar-item" active-class="is-active" :to="{ path: '/' }" exact>البراري</router-link>
            <router-link class="navbar-item" active-class="is-active" :to="{ path: '/about' }" exact>عن الموقع</router-link>
            <router-link class="navbar-item" active-class="is-active" :to="{ path: '/terms' }" exact>الشروط والأحكام</router-link>
            <router-link class="navbar-item" active-class="is-active" :to="{ path: '/contact' }" exact>للتواصل</router-link>
            <router-link v-if="getLogged" class="navbar-item" active-class="is-active" :to="{ path: '/posts' }">إضافاتي</router-link>
            <router-link v-if="getLogged && getAdmins" class="navbar-item" active-class="is-active" :to="{ path: '/control' }">التحكم</router-link>
          </div>
          <div class="navbar-end">
            <div class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-item">
                <b-icon icon="account" class="has-text-primary" />
                <div class="navbar-dropdown is-boxed">
                  <router-link class="navbar-item" active-class="is-active" :to="{ path: '/login' }" v-if="!getLogged">دخول</router-link>
                  <router-link class="navbar-item" active-class="is-active" :to="{ path: '/control/profile' }" v-if="getLogged">الحساب</router-link>
                  <hr class="navbar-divider" v-if="getLogged">
                  <a class="navbar-item"  @click="clearAccount" v-if="getLogged">خروج</a>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    // From API
    return {
      title: 'Kalimah'
    }
  },
  created () {
    this.$store.dispatch('prepAdmins')
  },
  methods: {
    openMenu: function () {
      var burger = document.getElementById('burger')
      var menu = document.getElementById('menu')
      burger.classList.toggle('is-active')
      menu.classList.toggle('is-active')
    },
    clearAccount: function () {
      this.$store.dispatch('clearAccount')
    }
  },
  computed: {
    ...mapGetters([
      'getAdmins',
      'getLogged'
    ])
  }
}
</script>
