'use strict'

import router from '../router'
import store from '../store'
import axios from 'axios'

const state = {
  account: {
    token: localStorage.getItem('XbToken'),
    refresh: localStorage.getItem('XbRefresh'),
    logged: localStorage.getItem('XbLogged'),
    user: localStorage.getItem('XbUser'),
    email: localStorage.getItem('XbEmail')
  }
}

const getters = {
  getToken: (state) => {
    return state.account.token
  },
  getLogged: (state) => {
    return state.account.logged
  },
  getUser: (state) => {
    return state.account.user
  },
  getEmail: (state) => {
    return state.account.email
  }
}

const mutations = {
  setAccount: (state, account) => {
    state.account = account
  }
}

const actions = {
  getAccount: ({ commit }, loginData) => {
    let account = {}
    let sendLogin = {
      appid: store.state.config.XbAppID,
      email: loginData.logEmail,
      password: loginData.logPassword
    }
    // Start the waiting loader until result get back
    store.dispatch('showLoader', true)
    axios.post('auth', sendLogin, { headers: { 'x-xbuffer-recaptcha': loginData.captcha } })
      .then(loginResult => {
        let getLogin = JSON.parse(loginResult.data.data)
        account = {
          token: getLogin.token,
          refresh: getLogin.refresh,
          logged: true,
          user: getLogin.user,
          email: getLogin.email
        }
        // Store sessions when correct
        localStorage.setItem('XbToken', account.token)
        localStorage.setItem('XbRefresh', account.refresh)
        localStorage.setItem('XbLogged', account.logged)
        localStorage.setItem('XbUser', account.user)
        localStorage.setItem('XbEmail', account.email)
        // Commit changes with stored session
        // hide login modal
        // redirect to account page
        // then remove the waiting loader
        commit('setAccount', account)
        store.dispatch('showLogin', false)
        router.push({ path: '/posts' })
        store.dispatch('showLoader', false)
      })
      .catch(error => {
        if (error.response) {
          account = {
            show: true,
            message: JSON.parse(error.response.data.message)
          }
        } else {
          account = {
            show: true,
            message: 'Unknown Error!'
          }
        }
        console.log(error)
        // dispatch error message handler to show error
        store.dispatch('showError', account)
        store.dispatch('showLoader', false)
      })
  },
  clearAccount: ({ commit }) => {
    // Remove sessions before procceding
    // to make sure user has loggedout
    localStorage.removeItem('XbToken')
    localStorage.removeItem('XbRefresh')
    localStorage.removeItem('XbLogged')
    localStorage.removeItem('XbUser')
    localStorage.removeItem('XbEmail')
    let account = {
      token: null,
      refresh: null,
      logged: false,
      user: null,
      email: null
    }
    commit('setAccount', account)
    router.push({ path: '/' })
    store.dispatch('showWaiting', false)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
