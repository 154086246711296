'use strict'

import Vue from 'vue'
import axios from 'axios'
import store from '../store'
import router from '../router'

const isJson = (json) => {
  var str = json.toString()
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

axios.interceptors.response.use(response => {
  return response
}, error => {
  var message = {}
  if (isJson(error.response.data.message)) {
    message = JSON.parse(error.response.data.message)
  } else {
    message = error.response.data.message
  }
  const originalRequest = error.config
  var response = {}
  if (error.response.status === 401 && message.code !== 'MSCAUT3037') {
    store.dispatch('clearAccount')
    router.push({ path: '/login' })
    return Promise.reject(error)
  }
  if (error.response.status === 401 && message.code === 'MSCAUT3037') {
    // originalRequest._retry = true
    return axios.post(`${store.state.login.user}/refresh`, null, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('XbRefresh')}`
      }
    }).then(result => {
      if (isJson(result.data.data)) {
        response = JSON.parse(result.data.data)
      } else {
        response = result.data.data
      }
      localStorage.setItem('XbToken', response.token)
      originalRequest.headers['Authorization'] = `Bearer ${response.token}`
      return axios(originalRequest)
    }).catch(error => {
      return Promise.reject(error)
    })
  }
  return Promise.reject(error)
})

Vue.mixin({
  methods: {
    XbortGetRequest: function (path, params, callback) {
      path = path || ''
      // Axios base settings
      let headers = null
      let token = localStorage.getItem('XbToken')
      if (token) {
        headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      } else {
        headers = {
          'Content-Type': 'application/json'
        }
      }
      axios({
        headers: headers,
        url: path,
        method: 'get',
        params: params
      }).then(getResponse => {
        let getResult = {}
        if (getResponse.data) {
          getResult = {
            result: true,
            data: isJson(getResponse.data.data) ? JSON.parse(getResponse.data.data) : getResponse.data.data
          }
        } else {
          getResult = {
            result: false,
            data: null
          }
        }
        callback(getResult)
      }).catch(getError => {
        let errorResult = {}
        if (getError.response) {
          let errorResponse = isJson(getError.response.data.message) ? JSON.parse(getError.response.data.message) : getError.response.data.message
          errorResult = {
            result: false,
            data: errorResponse.message
          }
          if (errorResponse.code && errorResponse.code === '[MSCIND3015]') {
            this.$store.dispatch('clearAccount')
            this.errorMessage(errorResult)
          } else {
            callback(errorResult)
          }
        }
      })
    },
    XbortGetMedia: function (file, size) {
      size = `&size=${size}` || ''
      if (file) {
        return `${store.state.config.XbUrl}/${store.state.config.XbPath}/${store.state.config.XbUser}/${store.state.config.XbProject}/?appid=${store.state.config.XbAppID}&type=media${size}&request=${file}`
      } else {
        return `https://api.adorable.io/avatars/200/${store.state.login.account.email}.png`
      }
    },
    XbortGetUserImage: function (users, author, size) {
      size = `&size=${size}` || ''
      let image = ''
      users.forEach(user => {
        if (user.userId === author) {
          if (user.userImage) {
            image = `${store.state.config.XbUrl}/${store.state.config.XbPath}/${store.state.config.XbUser}/${store.state.config.XbProject}/?appid=${store.state.config.XbAppID}&type=media${size}&request=${user.userImage}`
          } else {
            image = `https://api.adorable.io/avatars/200/${user.userEmail}.png`
          }
        }
      })
      return image
    },
    XbortGetUserName: function (users, author) {
      let name = ''
      users.forEach(user => {
        if (user.userId === author) {
          if (user.userName) {
            name = user.userName
          } else {
            name = user.userEmail
          }
        }
      })
      return name
    },
    XbortRequest: function (path, method, params, callback) {
      path = path || ''
      // Axios base settings
      let headers = null
      let token = store.state.login.account.token
      if (token) {
        headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      } else if (params.captcha && (path === 'signup' || path === 'login' || path === 'account' || path === 'reset')) {
        headers = {
          'Content-Type': 'application/json',
          'x-xbuffer-recaptcha': params.captcha
        }
      } else {
        headers = {
          'Content-Type': 'application/json'
        }
      }
      axios({
        headers: headers,
        url: path,
        method: method,
        data: params
      }).then(postResponse => {
        console.log(postResponse)
        let postResult = {}
        if (postResponse.data) {
          postResult = {
            result: true,
            data: isJson(postResponse.data.data) ? JSON.parse(postResponse.data.data) : postResponse.data.data
          }
        } else {
          postResult = {
            result: false,
            data: null
          }
        }
        callback(postResult)
      }).catch(postError => {
        console.log(postError)
        let errorResult = {}
        if (postError.response) {
          let errorResponse = isJson(postError.response.data.message) ? JSON.parse(postError.response.data.message) : postError.response.data.message
          console.log(postError.response.data)
          errorResult = {
            result: false,
            data: errorResponse.message
          }
          if (errorResponse.code && errorResponse.code === '[MSCIND3015]') {
            this.$store.dispatch('clearAccount')
            this.errorMessage(errorResult)
          } else {
            callback(errorResult)
          }
        }
      })
    },
    XbortDeleteRequest: function (path, params, callback) {
      path = path || ''
      // Axios base settings
      let headers = null
      let token = store.state.login.account.token
      if (token) {
        headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      } else {
        headers = {
          'Content-Type': 'application/json'
        }
      }
      axios({
        headers: headers,
        url: path,
        method: 'delete',
        params: params
      }).then(getResponse => {
        let getResult = {}
        if (getResponse.data) {
          getResult = {
            result: true,
            data: isJson(getResponse.data.data) ? JSON.parse(getResponse.data.data) : getResponse.data.data
          }
        } else {
          getResult = {
            result: false,
            data: null
          }
        }
        callback(getResult)
      }).catch(getError => {
        let errorResult = {}
        if (getError.response) {
          let errorResponse = isJson(getError.response.data.message) ? JSON.parse(getError.response.data.message) : getError.response.data.message
          errorResult = {
            result: false,
            data: errorResponse.message
          }
          if (errorResponse.code && errorResponse.code === '[MSCIND3015]') {
            this.$store.dispatch('clearAccount')
            this.errorMessage(errorResult)
          } else {
            callback(errorResult)
          }
        }
      })
    }
  }
})
