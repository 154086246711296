'use strict'
// 'https://api.xbuffer.net/v1',
export const kmSettings = {
  XbUrl: 'https://api.xbuffer.net/v1',
  XbPath: 'client',
  XbUser: 'madinahwild',
  XbProject: 'wildlife',
  XbDirectory: 'madinahwild',
  XbProfiles: 'profiles',
  XbAppID: 'ca30e77f1bab462d6f6dba0d75ff1037f2c833d0c06dd5b1313b37629fb90ecc:web'
}
