<template>
  <div id="app">
    <Head />
    <router-view/>
    <Foot />
  </div>
</template>

<script>
// Get users logged status
import { mapGetters } from 'vuex'

// Get header and footer components
import Head from './components/Head'
import Foot from './components/Foot'

export default {
  name: 'app',
  components: {
    Head,
    Foot
  },
  computed: {
    ...mapGetters([
      'getLogged'
    ])
  }
}
</script>
