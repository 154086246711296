import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import './mixins/xbort'
import './mixins/helpers'
import './filters'
import VeeValidate from 'vee-validate'
import * as VueGoogleMaps from 'vue2-google-maps'

// CSS libraries
import Buefy from 'buefy'
// import 'buefy/dist/buefy.min.css'
import '@/assets/css/madinahwild.scss'
import '@/assets/css/kalimah.css'
import '@/assets/css/kalimah-rtl.css'

Vue.use(Buefy, {
  defaultIconPack: 'mdi'
})

Vue.use(VeeValidate)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCIIKFlFbehQHcim-1kXpP7F-SRUIJpJQE'
  }
})
axios.defaults.baseURL = `${store.state.config.XbUrl}/${store.state.config.XbPath}/${store.state.config.XbUser}/${store.state.config.XbProject}`

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
